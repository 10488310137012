<template>
  <b-row>
    <b-col cols="12" class="heading-text">
      <b-row>
        <b-col> Nature of Contract :- {{ contract_nature }} </b-col>
        <b-col> Movie :- {{ movie_title }} </b-col>
        <b-col> Licensor/Licensee :- {{ vendor_company }} </b-col>
        <b-col> MG :- {{ mgadvance }} </b-col>
      </b-row>
    </b-col>
    <hr class="dotted" />

    <b-col><h3>Milestones</h3></b-col>
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        empty-text="No matching records found"
        show-empty
        :items="milestones"
        :fields="milestoneFeilds"
      >
        <template #head(index)> # </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(amountpayable)="data">
          {{ `${data.value} ${currency_code ? currency_code : ""}` }}
        </template>
        <template #cell(duedate)="data">
          <flat-pickr
            placeholder="Due Date"
            class="form-control"
            :value="data.value"
            :config="{
              ...$flatPickrConfig,
            }"
            @input="setMilestoneTableValue(data.index, 'duedate', $event)"
          />
        </template>
        <template #cell(currency)="data">
          <b-form-select
            :value="data.value"
            @input="setMilestoneTableValue(data.index, 'currency', $event)"
          >
            <b-form-select-option
              v-for="(currency, c_index) in $store.state.master.currency"
              :key="'c_currency' + c_index"
              :value="currency.lbl_id"
            >
              {{ currency.lbl_value1 }}
            </b-form-select-option>
          </b-form-select>
        </template>
        <template #cell(exchange_rate)="data">
          <b-form-input
            placeholder="Exchange Rate"
            :value="data.item.exchange_rate"
            @input="setMilestoneTableValue(data.index, 'exchange_rate', $event)"
          />
        </template>
        <template #cell(exchange_amount)="data">
          {{ getExchangeAmount(data.item) }}
        </template>
        <template #cell(amountpaid)="data">
          <b-form-input
            placeholder="Amount Paid"
            :value="data.item.amountpaid"
            @input="setMilestoneTableValue(data.index, 'amountpaid', $event)"
          />
        </template>
        <template #cell(paymentdate)="data">
          <flat-pickr
            placeholder="Payment Date"
            class="form-control"
            :value="data.value"
            :config="{
              ...$flatPickrConfig,
            }"
            @input="setMilestoneTableValue(data.index, 'paymentdate', $event)"
          />
        </template>
        <template #cell(comment)="data">
          <b-form-input
            placeholder="Comment"
            :value="data.item.comment"
            @input="setMilestoneTableValue(data.index, 'comment', $event)"
          />
        </template>
        <template #cell(action)="data">
          <b-button variant="primary" @click="saveMileStones(data.item)"
            >SAVE</b-button
          >
        </template>
      </b-table>
    </b-col>

    <hr class="dotted" />
    <b-col><h3>Bumpers</h3></b-col>
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        empty-text="No matching records found"
        show-empty
        :items="bumpers"
        :fields="bumperFeilds"
      >
        <template #head(index)> # </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(date)="data">
          {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
        </template>
        <template #cell(amount_paid)="data">
          <b-form-input
            placeholder="Amount Paid"
            :value="data.value"
            @input="setBumperTableValue(data.index, 'amount_paid', $event)"
          />
        </template>
        <template #cell(payment_date)="data">
          <flat-pickr
            placeholder="Payment Date"
            class="form-control"
            :value="data.value"
            :config="{
              ...$flatPickrConfig,
            }"
            @input="setBumperTableValue(data.index, 'payment_date', $event)"
          />
        </template>
        <template #cell(comment)="data">
          <b-form-input
            placeholder="Comment"
            :value="data.value"
            @input="setBumperTableValue(data.index, 'comment', $event)"
          />
        </template>
        <template #cell(action)="data">
          <b-button variant="primary" @click="saveBumpers(data.item)"
            >SAVE</b-button
          >
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import * as ContractServices from "@/apiServices/ContractServices";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { labels } from "@/utils/constants";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormInput,
    flatPickr,
    vSelect,
    BFormSelect,
    BFormSelectOption,
  },
  props: ["contract_id"],
  data() {
    return {
      // Milestones
      milestoneFeilds: [
        {
          key: "index",
        },
        {
          key: "milestone_name",
          label: "Title",
        },
        {
          key: "payable",
          label: "%",
        },
        {
          key: "amountpayable",
          label: "Amount",
        },
        {
          key: "duedate",
          label: "Due Date",
        },
        {
          key: "comment",
          label: "Comment",
        },
        // {
        //   key: "currency",
        //   label: "Currency",
        // },
        // {
        //   key: "exchange_rate",
        //   label: "Exchange Rate",
        // },
        // {
        //   key: "exchange_amount",
        //   label: "Exchange Amount",
        // },
        {
          key: "amountpaid",
          label: "Amount Paid CHF",
        },
        {
          key: "paymentdate",
          label: "Date of Payment",
        },
        {
          key: "action",
          label: "Actions",
        },
      ],
      milestones: [],

      // Bumper
      bumperFeilds: [
        {
          key: "index",
        },
        {
          key: "bumper_name",
          label: "Title",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "date",
          label: "Due Date",
        },
        {
          key: "comment",
          label: "Comment",
        },
        {
          key: "amount_paid",
          label: "Amount Paid CHF",
        },
        {
          key: "payment_date",
          label: "Payment Date",
        },
        {
          key: "action",
          label: "Actions",
        },
      ],
      bumpers: [],

      // Contract Data
      mgadvance: "",
      currency_name: "",
      currency_code: "",
      vendor_company: "",
      movie_title: "",
      contract_nature: "",
    };
  },
  methods: {
    moment,
    getExchangeAmount(milestone) {
      let { exchange_rate, amountpayable } = milestone;
      if (
        exchange_rate &&
        !isNaN(exchange_rate) &&
        amountpayable &&
        !isNaN(amountpayable)
      ) {
        exchange_rate = Number(exchange_rate);
        amountpayable = Number(amountpayable);

        return exchange_rate * amountpayable;
      }
      return "";
    },
    setMilestoneTableValue(index, key, value) {
      this.milestones[index][key] = value;
    },
    setBumperTableValue(index, key, value) {
      this.bumpers[index][key] = value;
    },
    async getContractTransactions() {
      try {
        const response = await ContractServices.GetContractTransactions(
          this.contract_id
        );
        if (response.data.status) {
          this.mgadvance = response.data.data.mgadvance;
          this.currency_name = response.data.data.currency_name;
          this.currency_code = response.data.data.currency_code;
          this.contract_nature = response.data.data.contract_nature;
          this.vendor_company = response.data.data.vendor_company;
          this.movie_title = response.data.data.movie_title;
          this.milestones = response.data.data.milestones;
          this.bumpers = response.data.data.bumpers;
        }
      } catch (error) {
        console.log("Error in getContractTransactions ", error);
      }
    },
    async saveMileStones(milestone) {
      try {
        console.log(typeof milestone.amountpaid);

        if (!milestone.amountpaid || isNaN(milestone.amountpaid)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please Enter Amount Paid",
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }

        if (!milestone.paymentdate) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please Enter Payment Date",
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }

        if (moment(milestone.paymentdate).isAfter(milestone.duedate)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Payment date exceeds Due Date",
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }

        const response = await ContractServices.SaveContractMileStones(
          milestone
        );
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Successful",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Falied",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
      } catch (error) {
        console.log("Error in saveMileStones ", error);
      }
    },
    async saveBumpers(bumper) {
      try {
        const response = await ContractServices.SaveContractBumper(bumper);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
      } catch (error) {
        console.log("Error in Saving Bumper");
      }
    },
  },
  mounted() {
    this.getContractTransactions();
    this.$store.dispatch("master/setLabelMaster", { key: labels.currency });
  },
};
</script>

<style>
hr.dotted {
  border-top: 2px dotted #999;
  width: 96%;
}
.heading-text {
  color: black;
  font-weight: bold;
}
</style>